export default {
    authorLabel: "Програмер",
    authorName: "Данијел Николић",

    websiteLabel: "Вебсајт",
    websiteLink: "https://danijelnik.com/",

    contactLabel: "Контакт",
    contactEmail: "danijel.nikolic@ymail.com",
    
    appDescription: "Конвертор валута је заувек бесплатна апликација која вам омогућава да конвертујете валуте и сачувате резултате. Ова апликација подржава 151 валутy и заснива се на подацима које пружају националне централне банке. Такође пружа историјске вредности валута почев од 2010. године. Вредности валута се ажурирају на сваких сат времена.",

    copy: "© Copyrihgt",
    copyDesc: "Сва права задржана",
    ownerName: "Данијел Николић"
}